import React from 'react';
import { CountDown } from '../../../CountDown/CountDown';
import { Header } from '../../../Header/Header';
import { Paragraph } from '../../../Paragraph/Paragraph';
import { GameActions } from '../../../TileActions/GameActions';
import { TilePill } from '../../../TilePill/TilePill';
import { TileProps } from '../../tiles';
import { Logo } from '../../../Logo/Logo';
import { CATEGORY_TILE_TAGLINE, GAME_UNAVAILABLE } from '../../../../helpers/tileHelper';
import { MegaTileLive } from '../MegaTileLive/MegaTileLive';
import { useMedia } from '@SharedScripts/Hooks/useMediaQuery';
import LiveStream from '../../../LiveStream/LiveStream';
import { track } from '../../../../helpers/tracking';

export const MegaTileBody = ({
  className = '',
  isLoading,
  bannerInfo,
  tag,
  isUnavailable,
  isTableClosed,
  description,
  potSize,
  minWager,
  dealerName,
  liveGamesText,
  liveStreamLink,
  playersText,
  navigationUrlWithLaunchParam,
  index,
  favourited,
  primaryButtonCallback,
  primaryButtonText,
  secondaryCallback,
  secondaryButtonText,
  buttonEnabled,
  title,
  subtitle,
  primaryButtonHref,
  secondaryButtonHref,
  openFrom,
  isActive,
  graphicsError,
  showLogo,
}: Partial<TileProps> & {
  graphicsError: boolean;
  isActive?: boolean;
  showLogo?: boolean;
}) => {
  const isCategoryLiveStream = liveStreamLink && tag === CATEGORY_TILE_TAGLINE;
  const { isMd } = useMedia();
  return (
    <div
      className={`absolute flex md:block bottom-0 left-0 w-full h-full min-h-64 rounded-32 ${isLoading
        ? ''
        : isActive && graphicsError
          ? 'md:bg-left-fallback-gradient bg-none'
          : 'md:bg-left-primary-gradient bg-none'
      } ${isActive ? 'relative opacity-100' : 'absolute opacity-0 bg-transparent'}`}
    >
      {showLogo && (
        <div className='absolute w-full h-full'>
          <Logo
            text={isLoading && isActive ? 'Loading...' : ''}
            textSize={20}
            className={`mx-auto w-160 gap-8 !fill-neutrals-grey-500 !text-neutrals-grey-500 
                        ${!(isLoading && isActive)
          ? 'translate-x-40 mt-112'
          : 'h-full justify-center text-center'
        }`}
          />
        </div>
      )}

      {isCategoryLiveStream && !(isLoading && isActive) &&
        <div className='absolute flex w-full h-full md:max-w-2/3'>
          <a href={navigationUrlWithLaunchParam}
            className='absolute -top-16 -right-12 md:top-[initial] md:bottom-24 md:right-24 w-128 h-128 overflow-hidden border-gold-ultraLight border-2 border-solid rounded-24 z-20'
            data-tracking-meta-data={JSON.stringify({
              action: 'Press to view live video',
              label: 'Press to view live video',
              category: tag,
              value: index ? index + 1 : 0,
              gameTitle: title,
            })}
            onClick={track}
          >
            <div className='absolute top-12 left-12 text-white fill-white z-10'>
              <TilePill icon='live-games' text='Live' className='!backdrop-blur-none'/>
            </div>
            <LiveStream url={liveStreamLink} className='absolute left-1/2 -translate-x-1/2 h-[12.4rem]'/>
          </a>
        </div>
      }

      {!(isLoading && isActive) && (
        <div className='absolute flex w-full p-16 md:hidden min-h-24 fill-neutrals-white text-neutrals-white'>
          {potSize && (
            <TilePill
              text={potSize}
              icon='jackpot'
              background={isUnavailable ? 'grey' : 'purple'}
              className={`${bannerInfo ? 'ml-16' : ''} z-30`}
            />
          )}
          {minWager && !isCategoryLiveStream && (
            <TilePill
              text={minWager}
              icon='bid'
              background={isUnavailable ? 'grey' : 'dark-grey'}
              className='ml-auto z-30'
            />
          )}
        </div>
      )}
      {!(isLoading && isActive) && (
        <div
          className='relative flex flex-col self-end w-full h-auto p-16 pb-32 m-0 md:h-full md:justify-center text-neutrals-white fill-neutrals-white'
        >
          <div className='overflow-hidden z-10 pt-64 md:pt-0'>
            <div
              className={`absolute bottom-0 left-0 h-full w-full rounded-b-32 md:max-w-2/3 md:rounded-r-none md:rounded-l-32 mask-gradient md:mask-gradient-left ${isLoading
                ? ''
                : isActive && graphicsError
                  ? 'md:bg-left-none bg-fallback-gradient'
                  : 'md:bg-left-none bg-gradient-to-t md:bg-gradient-to-tr from-black/70 to-transparent backdrop-blur-[2px] md:backdrop-blur-none'
              } `}
            />
          </div>

          <div
            className='md:max-w-1/2 lg:max-w-1/3 md:mt-auto md:mx-40 md:pt-12 z-10'
          >
            <div
              className={`relative flex flex-col justify-end md:block h-full md:h-auto transition-opacity duration-300 ease-in-out ${className}`}
            >
              {!isLoading && !isUnavailable && isTableClosed && (
                <CountDown
                  className='mb-12 text-white text-20 w-fit'
                  openingTime={openFrom ?? ''}
                  textStyle='!max-w-full'
                />
              )}

              {tag && (
                <Header
                  as='h5'
                  size='xs'
                  className='leading-7 md:leading-8 mb-12'
                >
                  {tag}
                </Header>
              )}

              {!isLoading && isUnavailable && (
                <Paragraph
                  size={14}
                  weight='bold'
                  className='mb-12 text-white md:!text-16'
                >
                  {GAME_UNAVAILABLE}
                </Paragraph>
              )}

              <Header
                as='h1'
                size='2xl'
                className='!text-24 md:!text-40 lg:!text-48 mb-8 md:mb-12 font-extrabold'
                italic={true}
                truncate={2}
              >
                {title ?? ''}
              </Header>

              <div className='flex flex-col w-full'>
                {description && ((isMd && subtitle) || !subtitle) && (
                  <Paragraph
                    size={14}
                    className='order-2 mb-8 leading-9 md:order-1'
                    truncate={2}
                  >
                    {description}
                  </Paragraph>
                )}
                {subtitle && !isMd && (
                  <Paragraph
                    size={10}
                    className='order-2 mb-8 leading-[1.8rem] md:order-1'
                    truncate={12}
                    italic
                  >
                    {subtitle}
                  </Paragraph>
                )}

                <MegaTileLive {...{ potSize, playersText, dealerName, liveGamesText, minWager, isTableClosed }} />
              </div>
            </div>
          </div>

          <div
            className='md:max-w-1/2 lg:max-w-1/3 md:mb-auto md:mx-40 md:pt-12'
          >
            <div
              className={`relative flex flex-col justify-end md:block h-full md:h-auto transition-opacity duration-300 ease-in-out ${className}`}
            >
              {!isUnavailable && isActive && (
                <GameActions
                  isTableClosed={isTableClosed}
                  href={navigationUrlWithLaunchParam}
                  linkAriaLabel={`Start spil ${title}`}
                  favourited={favourited}
                  favouritedAriaLabel={`Føj ${title} til favoritter`}
                  primaryButtonCallback={primaryButtonCallback}
                  playSize='large'
                  favoriteSize='large'
                  buttonEnabled={buttonEnabled}
                  primaryButtonText={primaryButtonText}
                  secondaryCallback={secondaryCallback}
                  secondaryButtonText={secondaryButtonText}
                  primaryButtonHref={primaryButtonHref}
                  secondaryButtonHref={secondaryButtonHref}
                  className={`absolute flex gap-12 self-center md:relative mt-10 is-desktop-detected:bottom-0 ${buttonEnabled ? 'bottom-12' : ''} translate-y-64 is-tablet-detected:translate-y-0 is-desktop-detected:translate-y-0 opacity-100 !z-50`}
                />
              )}
            </div>
          </div>

          {subtitle && isMd && (
            <div className='z-10 md:max-w-2/3'>
              <Paragraph
                size={10}
                className='md:mx-40 leading-[1.8rem]'
                truncate={10}
                italic
              >
                {subtitle}
              </Paragraph>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
